import { FC } from 'react';
import IconProps from './IconProps';

const InternalDynamicDataIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.88881" cy="3.3329" r="1.55556" fill="currentColor" />
      <circle cx="11.111" cy="12.6649" r="1.55556" fill="currentColor" />
      <path d="M4.88892 4.10938V10.3316C4.88892 11.6203 5.93359 12.6649 7.22225 12.6649H10.3334" stroke="currentColor" strokeWidth="0.777778" />
    </svg>
  );
};

export default InternalDynamicDataIcon;
